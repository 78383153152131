import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, updateUser } from '../../redux/userRelated/userHandle';
import { useNavigate } from 'react-router-dom'
import { authLogout } from '../../redux/userRelated/userSlice';
import { Box, Button, Collapse, Grid, TextField } from '@mui/material';
import styled from 'styled-components';

// import { useSelector } from 'react-redux';

const AdminProfile = () => {
    const [showTab, setShowTab] = useState(false);
    const buttonText = showTab ? 'Cancel' : 'Edit profile';

    const navigate = useNavigate()
    const dispatch = useDispatch();
        // const { currentUser } = useSelector((state) => state.user);
    const { currentUser, response, error } = useSelector((state) => state.user);
    const address = "Admin"

    if (response) { console.log(response) }
    else if (error) { console.log(error) }

    const [name, setName] = useState(currentUser.name);
    const [email, setEmail] = useState(currentUser.email);
    const [password, setPassword] = useState("");
    const [schoolName, setSchoolName] = useState(currentUser.schoolName);

    const fields = password === "" ? { name, email, schoolName } : { name, email, password, schoolName }

    const submitHandler = (event) => {
        event.preventDefault()
        dispatch(updateUser(fields, currentUser._id, address))
    }

    const deleteHandler = () => {
        try {
            dispatch(deleteUser(currentUser._id, "Students"));
            dispatch(deleteUser(currentUser._id, address));
            dispatch(authLogout());
            navigate('/');
        } catch (error) {
            console.error(error);
        }
    }

    return (
      <StyledDiv>
        <UserInfoContainer>
          <span><strong>Name:</strong> {currentUser.name}</span>
          <span><strong>Email:</strong> {currentUser.email}</span>
        </UserInfoContainer>
        <Grid container spacing={2} sx={{marginBottom: '16px'}}>
          <Grid item xs={12} md={6}>
            <Button variant="contained" color="error" onClick={deleteHandler} disabled='Disabled'>
              Delete
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              sx={styles.showButton}
              onClick={() => setShowTab(!showTab)}
            >
              {showTab ? <KeyboardArrowUp /> : <KeyboardArrowDown />}{buttonText}
            </Button>
          </Grid>
        </Grid>
        <Collapse in={showTab} timeout="auto" unmountOnExit>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{padding: '0px 20px 10px 20px',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
                <TextField
                  label="Name"
                  variant="standard"
                  fullWidth
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  autoComplete="name"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{padding: '0px 20px 10px 20px',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
                <TextField
                  label="Email"
                  variant="standard"
                  fullWidth
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  autoComplete="email"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{padding: '0px 20px 10px 20px',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
                <TextField
                  label="Password"
                  variant="standard"
                  fullWidth
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  autoComplete="password"
                  required
                />
              </Box>
            </Grid>
            {/* ... Add similar Grid items for other form fields */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={submitHandler}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </StyledDiv>
    );
  };
  
  export default AdminProfile;

  const styles = {
    attendanceButton: {
        backgroundColor: "#270843",
        "&:hover": {
            backgroundColor: "#3f1068",
        }
    },

    showButton: {
      // marginTop: '14px'
    }
}

const StyledDiv = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-height: 450px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: linear-gradient(to bottom, #fad5ae, #fde8d4, #f9cb9c);
  border-radius: 12px;

  h4 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  button {
    background-color: #270843;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #3f1068;
    }
  }
`;

const UserInfoContainer = styled.div`
  margin-bottom: 20px;
  padding: 25px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;

  span {
    display: block;
    margin-bottom: 10px;
    font-size: 1.1rem;
  }

  strong {
    color: #270843;
  }
`;